<template>
  <div id="app">
    <b-navbar v-if="$route.name !== 'Panel-Taxistas'" toggleable="xl" class="navbar sticky-top navbar-light navbar-expand-md barra">
      <b-navbar-brand class="navbar-brand" v-if="scrollPosition > 0">
        <a href="/" target="_self" class="navbar-brand">
          <img src="./assets/kbo_logo.png" width="100" height="50" alt="logo">
        </a>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="$route.name === 'Home'" class="nav-btns">
          <b-nav-item id="inicio" href="/" target="_self" class="nav-link">Inicio</b-nav-item>
          <b-nav-item id="precios" href="#campaña" target="_self" class="nav-link">Precios</b-nav-item>
          <b-nav-item id="taxi" href="#slider-taxi" target="_self" class="nav-link">Taxi</b-nav-item>
          <b-nav-item id="operadoras" href="#operadoras-slider" target="_self" class="nav-link">Operadoras</b-nav-item>
          <b-nav-item id="presentacion" href="#present" target="_self" class="nav-link">Presentación</b-nav-item>
          <b-nav-item id="contacto" href="#contact" target="_self" class="nav-link">Contacto</b-nav-item>
        </b-navbar-nav>
       
        <b-nav-item-dropdown text="Acceso" class="nav-link acceso">
          <b-dropdown-item class="drop-button" v-if="token === null"><router-link class="dropdown-buttons" to="/registro-taxistas">Taxistas</router-link></b-dropdown-item>
          <b-dropdown-item class="drop-button" v-if="token !== null"><router-link class="dropdown-buttons" to="/login-taxistas">Taxistas</router-link></b-dropdown-item>
          <b-dropdown-item class="drop-button"><router-link class="dropdown-buttons" to="/login-comercios">Comercios</router-link></b-dropdown-item>
          <b-dropdown-item class="drop-button"><router-link class="dropdown-buttons" to="/login-clientes">Clientes</router-link></b-dropdown-item>
          <b-dropdown-item class="drop-button"><router-link class="dropdown-buttons" to="/login-administradores">Administradores</router-link></b-dropdown-item>
        </b-nav-item-dropdown>   
       </b-collapse>
    </b-navbar>
     
    <router-view
    @inputdone="getToken()"
    @logoutdone="removeToken()"
 
    />
  </div>
</template>

<script>
// Importamos JQuery
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

export default {
  name: 'App',
  
  data() {
    return {
      token: null,
      scrollPosition: '',
    }
  },
  methods: {
    //FUNCION PARA RECUPERAR EL TOKEN DESDE LOCALSTORAGE
    getAuthToken() {
      return localStorage.getItem('AUTH_TOKEN_KEY')
    },
    getToken() {
      this.token = this.getAuthToken()
      console.log(this.token);
    },
    removeToken() {
      this.token = this.getAuthToken()
      console.log(this.token);
    },
    updateScroll() {
      $(window).scroll(function() {
        if ($(document).scrollTop() > 0) {
          $('nav').addClass('shrink');
          $('b-navbar-brand').addClass('shrink');
          $('b-navbar-nav').addClass('shrink');
         
        } else {
          $('nav').removeClass('shrink');
           $('b-navbar-brand').removeClass('shrink');
          $('b-navbar-nav').removeClass('shrink');
        }
      });
    },
     scrollChanging() {
          this.scrollPosition = window.scrollY
          console.log(this.scrollPosition);
      }
  },
  mounted() {
      window.addEventListener('scroll', this.scrollChanging);
  }, 
  created() {
      this.getToken(),
      this.removeToken(),
      this.updateScroll()
  },
}
</script>

<style>
* {
  box-sizing: border-box;
  border: 0 solid;
  scroll-behavior: smooth;
}
/*navbar*/
.navbar-light {
 background: transparent;
}
.navbar-light, .nav-link {
  font-weight: 400;
  font-size: 1.1rem;
  color: rgb(255, 255, 255) !important;
}
.navbar-light, .nav-link:hover {
  color: #007196 !important;
}
::marker {
  display: none;
  color: transparent;
  background-color: transparent;
}
.nav-btns {
  margin-left: 41%;
  font-size: 1rem;
  color: white;
}                                         
.acceso {
  text-decoration: none;
  outline: none;
  font-size: 1em;
}
.dropdown-buttons {
  color:black !important;
  text-decoration: none;
}
.dropdown-buttons:hover {
  color: #007196 !important;
  text-decoration: none;
}
.navbar-brand {
  margin-left: 15%;
}
nav.navbar-brand.shrink {
  visibility:visible;
}
.navbar.shrink {
  background-color: white !important;
  position:fixed;
  width: 100%;
  height: 3.9em;
  left: 0px;
  transition: all 1.5s ease;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.12),inset 0 -1px 0 0 #dadce0;
  transform: translate3d(0,0,0);
}
nav.navbar.shrink a {
  color: black !important;
  font-size: 1.1em; 
  justify-content: space-between !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  transition: all 1.5s ease;
}
nav.navbar.shrink .navbar-toggler {
  color: black;
  margin: 8px 15px 8px 0;
  padding: 4px 5px;
  transition: all 1.5s ease;
}
nav.navbar.shrink a:hover {
  color: #007196 !important;
}
nav.navbar.shrink ul.dropdown-menu.dropdown-menu-right.show {
  background-color: white !important;
  padding: 0;
  outline: none;
}
nav.navbar.shrink .dropdown-buttons:hover {
  color: #007196 !important;
}

/*MEDIA QUERIES*/
/* Small devices (portrait phones, 376px max)*/
@media (max-width: 375.98px) {
/*navbar*/
.barra { 
  display: flex; 
  flex-wrap: wrap; 
  justify-content: space-between; 
  align-items: left; 
} 
.nav-link { 
  width: 85%; 
  order: 3; 
  display: none;
  margin-left: 1rem; 
}
.show {
  margin-right: 15rem;
  margin-left: -8rem;
}
.acceso {
  text-decoration: none;
  outline: none;
  font-size: 1em;
  margin-left: 5rem;
  background-color: transparent;
  width: 63.5%;
}
.dropdown-item {
  margin-left: 1rem;
  font-size: 1em;
}
.navbar-brand {
  margin-top: -0.5rem;
  margin-left: 0.5rem;
}
.navbar-toggler-icon {
  float: right;
}
nav.navbar.shrink .navbar-toggler {
  color: black;
  margin: 8px 40px 8px 0;
  padding: 4px 5px;
  transition: all 1.5s ease;
  background-color: white;
}
nav.navbar.shrink a {
  color: black !important;
  font-size: 0.8em; 
  justify-content: space-between !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  transition: all 1.5s ease;
  background-color: white;
  line-height: 1vw;
}
nav.navbar.shrink li {
  background-color: white;
}
}
/* Small devices (big phones, phablets 376px and up)*/
@media (min-width: 376px) and (max-width: 575.98px) {
/*navbar*/
.barra { 
  display: flex; 
  flex-wrap: wrap; 
  justify-content: space-between; 
  align-items: left; 
} 
.nav-link { 
  width: 85%; 
  order: 3; 
  display: none;
  margin-left: 1rem; 
}
.show {
  margin-right: 15rem;
  margin-left: -17rem;
}
.acceso {
  text-decoration: none;
  outline: none;
  font-size: 1em;
  margin-left: 13.8rem;
  background-color: transparent;
  width: 55.5%;
}
.dropdown-item {
  margin-left: 1rem;
  font-size: 1em;
}
.navbar-brand {
  margin-top: -0.5rem;
  margin-left: 0.5rem;
}
.navbar-toggler-icon {
  float: right;
}
nav.navbar.shrink .navbar-toggler {
  color: black;
  margin: 8px 40px 8px 0;
  padding: 4px 5px;
  transition: all 1.5s ease;
  background-color: white;
}
nav.navbar.shrink a {
  color: black !important;
  font-size: 0.8em; 
  justify-content: space-between !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  transition: all 1.5s ease;
  background-color: white;
  line-height: 1vw;
}
nav.navbar.shrink li {
  background-color: white;
}
}
/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
/*navbar*/
.barra { 
  display: flex; 
  flex-wrap: wrap; 
  justify-content: space-between; 
  align-items: left; 
} 
.nav-link { 
  width: 85%; 
  order: 3; 
  display: none;
  margin-left: 1rem; 
}
.show {
  margin-right: 15rem;
  margin-left: -25rem;
}
.acceso {
  text-decoration: none;
  outline: none;
  font-size: 1em;
  margin-left: 22rem;
  background-color: transparent;
  width: 53.6%;
}
.dropdown-item {
  margin-left: 1rem;
  font-size: 1em;
}
.navbar-brand {
  margin-top: -0.5rem;
  margin-left: 0.5rem;
}
.navbar-toggler-icon {
  float: right;
}
nav.navbar.shrink .navbar-toggler {
  color: black;
  margin: 8px 40px 8px 0;
  padding: 4px 5px;
  transition: all 1.5s ease;
  background-color: white;
}
nav.navbar.shrink a {
  color: black !important;
  font-size: 0.8em; 
  justify-content: space-between !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  transition: all 1.5s ease;
  background-color: white;
  line-height: 1vw;
}
nav.navbar.shrink li {
  background-color: white;
}
}
/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
/*navbar*/
.nav-btns {
  margin-left: 25%;
}                                         
.navbar-brand {
  margin-left: 1%;
}
nav.navbar.shrink a {
  font-size: 0.8em; 
}
}
/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  /*navbar*/
.nav-btns {
  margin-left: 35%;
}                                         
.navbar-brand {
  margin-left: 1%;
}
nav.navbar.shrink a {
  font-size: 0.8em; 
}
}
</style>
