<template>
 <div class="operadoraslider">
  <splide :options="options">
    <splide-slide>
      <img class="img_slide_operadora" src="https://2kbo.com/imgs/operatorappscreenshots/1.jpg">
    </splide-slide>
    <splide-slide>
      <img class="img_slide_operadora" src="https://2kbo.com/imgs/operatorappscreenshots/2.jpg">
    </splide-slide>
    <splide-slide>
      <img class="img_slide_operadora" src="https://2kbo.com/imgs/operatorappscreenshots/3.jpg">
    </splide-slide>
    <splide-slide>
      <img class="img_slide_operadora" src="https://2kbo.com/imgs/operatorappscreenshots/4.jpg">
    </splide-slide>
    <splide-slide>
      <img class="img_slide_operadora" src="https://2kbo.com/imgs/operatorappscreenshots/5.jpg">
    </splide-slide>
    <splide-slide>
      <img class="img_slide_operadora" src="https://2kbo.com/imgs/operatorappscreenshots/6.jpg">
    </splide-slide>
    <splide-slide>
      <img class="img_slide_operadora" src="https://2kbo.com/imgs/operatorappscreenshots/7.jpg">
    </splide-slide>
    <splide-slide>
      <img class="img_slide_operadora" src="https://2kbo.com/imgs/operatorappscreenshots/8.jpg">
    </splide-slide>
  </splide>
 </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

export default {
  name: 'OperadoraSlider',
  components: {
    Splide,
    SplideSlide,
  },
   data() {
    return {
      slider: 0,
      sliding: null,
      options: {
		      rewind : true,
		      arrows : true,
          perPage: 1,
          heightRatio: 1,
		      gap    : '1rem',
          type   : 'slide',
          autoplay: false,
          width: "100%",
          breakpoints: {
		        575: {
			        arrows: false,
              pagination: false
            },
            767: {
              arrows: false,
              pagination: false
            },
            991: {
              arrows: false,
              pagination: false
            },
            1199: {
              arrows: true,
              pagination: true
            }
          },
        },
    } 
   },
    methods: {
   
    onSlideStart(slider) {
        this.sliding = true
      },
    onSlideEnd(slider) {
        this.sliding = false
      },
    },
}
</script>

<style lang="scss" src="../scss/estilos.scss" scoped>

</style>
