<template>
  <div class="home">
    <vue-headful
    title="Inicio | KBO" />
    <div class="header-img"></div>
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100 lg-size-200"
          >
            <h1 class="landing_title">
              <img class="logo"
                src="../assets/kbo_logo.png"
                width="300"
                height="150"
                alt="logo"
              />
            </h1>
            <h4 class="landing_sub_title">
              Soluciones Integrales para la<br> 
              Gestión de Flotas de Taxis
            </h4>
            <br />
          </div>
        </div>
      </div>
       
    <div class="container-fluid bg-white campaña">
      <div class="row justify-content-center">
        <div class="col-12">
          <h1 id="campaña" class="row justify-content-center mt-5"><b>CAMPAÑA 2019/2020.</b></h1>
          <h3 class="row justify-content-center mt-5">En KBO ofrecemos condiciones muy especiales para nuevas altas. Sin letra pequeña.<br></h3>
          <h3 class="row justify-content-center">Y compromiso de no subir nunca los precios.</h3>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-white contain_precios">
       <div class="row align-items-center">
        <div class="col-12 col-md">
          <div class="preciotaxi-contain mt-5 mb-5 mr-5 ml-5 bg-white">
            <div class="row">
              <div class="col-12">
              <figure>
                  <img class="btn-preciotaxi" src="../assets/precioxtaxi.jpg" width="350" height="250"> 
                    <h2 id="precio" class="row justify-content-center precioxtaxi-title mt-3">Precio por taxi</h2>
                    <p class="row justify-content-center precioxtaxi_text mt-3">
                      Este precio lo incluye todo. 
                    </p>
              </figure>
              </div>
            </div>
          </div>
          <div class="kbt-contain mt-5 mb-5 mr-5 ml-5 bg-white">
            <div class="row">
              <div class="col-12">
                <figure>
                    <img class="btn-kbt" src="../assets/kbt.jpg" width="350" height="250"> 
                      <h2 class="row justify-content-center kbt-title mt-3">KBT</h2>
                      <p class="row justify-content-center kbt_text mt-3">
                        Dispositivo bluetooth. 
                      </p>
                </figure>
              </div>
            </div>  
          </div>
          <div class="alta-contain mt-5 mb-5 mr-5 ml-5 bg-white">
            <div class="row">
              <div class="col-12">
                <figure>
                    <img class="btn-alta" src="../assets/alta.jpg" width="350" height="250"> 
                      <h2 class="row justify-content-center alta-title mt-3">Alta Gratis</h2>
                      <p class="row justify-content-center alta_text mt-3">
                        Sin coste de alta. 
                      </p>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-light contain-fondo-app_taxi_slider">
       <div class="container taxis_header">
        <div class="row">
          <div class="col-12">
            <h1 id="slider-taxi" class="row justify-content-center slider-taxi"><b>Aplicación móvil para Taxis</b></h1>
            <h3 class="row justify-content-center mt-3 slider-taxi">En KBO ofrecemos condiciones muy especiales para nuevas altas. Sin letra pequeña.<br></h3>
            <h3 class="row justify-content-center mb-3 slider-taxi">Y compromiso de no subir nunca los precios.</h3>
          </div>
        </div>
       </div>
      <div class="taxi_app"></div>
      <div class="row">
        <div class="col-12 taxi_margen">
          <div class="container contain-app_taxi_slider" id="carousel-1">
            <div class="row">
              <div class="col-12">
                <taxi-slider/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="container-fluid bg-white" id="present">
      <div class="row">
        <div class="col-12">
          <h1 id="caracteristica" class="row justify-content-center"><b>Siempre más. Siempre mejor.</b></h1>
          <h3 class="row justify-content-center mt-5">En KBO desarrollamos la más completa Solución Informática para la Gestión de Flotas de Taxis.<br></h3>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-white contain-splide_presentacion">
      <div class="row">
        <div class="col-12">
          <presentacion-slider/>
        </div>
      </div>     
    </div>
   
    <div class="container-fluid bg-light mt-5 contain-fondo-slider_operadoras" id="operadoras-slider">
      <div class="container operadoras_header">
        <div class="row">
          <div class="col-12">
            <h1 id="campaña" class="row justify-content-center slider-operadora"><b>Aplicación para Operadoras</b></h1>
            <h3 class="row justify-content-center mt-3 slider-operadora">En KBO ofrecemos condiciones muy especiales para nuevas altas. Sin letra pequeña.<br></h3>
            <h3 class="row justify-content-center mb-3 slider-operadora">Y compromiso de no subir nunca los precios.</h3>
          </div>
        </div>
      </div>
      <div class="operadora_app"></div>
      <div class="row align-items-center">
        <div class="col-12">
          <div class="container bg-transparent contain-slider_operadoras">
            <div class="row align-items-center">
              <div class="col-12">
                <operadora-slider/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div id="contact" class="container-fluid contain-contacto">
      <div class="row justify-content-center text-center p-3 contacto">
        <div class="col-12">
          <h3 class="productos">Si quieres contactar acerca de nuestros productos:</h3>
        </div>
      </div>
      <div class="container-fluid email">
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-4">
            <figure>
              <p class="col justify-content-center contact_text mt-3 mb-1">
                <u class="contact_title">Por correo electrónico:</u></p><br>
                  <p class="col justify-content-center contact_text mb-1"><a href="administracion@2kbo.com">administracion@2kbo.com</a>;&nbsp; para administración.</p><br>
                  <p class="col justify-content-center contact_text mb-1"><a href="info@2kbo.com">info@2kbo.com</a>;&nbsp; para información general</p><br>
                  <p class="col justify-content-center contact_text mb-1"><a href="soporte@2kbo.com">soporte@2kbo.com</a>;&nbsp; para soporte técnico</p><br>
            </figure>
          </div>
          <div class="col col-md-4">
            <figure>
              <p class="col justify-content-center contact_text mt-3 mb-1">
                <u class="contact_title">Por correo postal:</u></p><br>
                  <p class="col justify-content-center contact_text mb-1">Sistema de gestión de flotas de taxis KBO</p><br>
                  <p class="col justify-content-center contact_text mb-1">Apartado de Correos 1234</p><br>
                  <p class="col justify-content-center contact_text mb-1">Vigo, 36210, España</p><br>
            </figure>
          </div>
        </div>
      </div>
       <div class="container-fluid footer">
        <div class="row justify-content-center">
          <div class="col">
            <figure>
              <p class="col justify-content-left marca">
                <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/cluf.html">Contrato de Licencia de Usuario Final</a>
                <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/politicaprivacidad.html">Política de Privacidad</a>
                <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/copyrightylicencia.html">Copyright y Licencia de software</a>
                <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/licenciasdeterceros.html">Licencias de terceros</a>
                <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/requerimientostecnicos.html">Requerimientos técnicos</a>
              </p>
            </figure>
          </div>
        </div>
       </div>
        <p class="marca_version">© KBO 2021 por <a class="nacho_rodriguez" target="_blank" href="https://www.nacho-rodriguez.com">Nacho Rodríguez</a>. Versión 1.0</p>
      </div>
     
    <!---BOTON FLECHA SCROLL UP
    <div class="container-fluid button-up" id="button-up">
      <button v-if="scrollPosition >= 400" class="up" @click="scrollUp(), goToHome()"><img src="../assets/arrow_up.png" width="75" height="100" alt=""></button>
    </div>
    -->
  </div>
</template>

<script>
import TaxiSlider from '@/components/TaxiSlider.vue';
import PresentacionSlider from '@/components/PresentacionSlider.vue';
import OperadoraSlider from '@/components/OperadoraSlider.vue';

export default {
  name: 'HomeView',
  components: {
    //scrollUp: '',
    TaxiSlider,
    PresentacionSlider,
    OperadoraSlider,
  },
  data() {
    return {
      //scrollPosition: '',
    } 
  },
  methods: {
    goToHome() {
      this.$router.push('/')
    },
    /**
    //BOTON FLECHA SCROLL UP
    scrollUp() {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if(currentScroll > 0) {
        window.requestAnimationFrame(this.scrollUp)
        window.scrollTo (0, currentScroll - (currentScroll / 50));
      }
    },
    updateScroll() {
          this.scrollPosition = window.scrollY
      }
  },
  */
  }
}
</script>

<style lang="scss" src="../scss/estilos.scss" scoped>

</style>
