<template>
  <div class="taxislider">
    <splide :options="options">
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/1.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/2.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/3.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/4.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/5.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/6.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/7.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/8.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/9.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/10.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/11.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/12.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/13.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/14.png">
        </splide-slide>
        <splide-slide>
        <img class="img_slide" src="https://2kbo.com/imgs/taxiappscreenshots/15.png">
        </splide-slide>
    </splide>
 </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

export default {
  name: 'TaxiSlider',
  components: {
    Splide,
    SplideSlide,
  },
   data() {
    return {
      slider: 0,
      sliding: null,
      options: {
		    rewind : true,
		    perPage: 1,
        heightRatio: 2,
		    gap: '1rem',
        type   : 'slide',
        autoplay: false,
        width: 550,
        breakpoints: {
          575: {
            arrows: false,
            pagination: false
          },
          767: {
            arrows: true,
            pagination: false
          },
          991: {
            arrows: false,
            pagination: false
          },
          1199: {
            arrows: true,
            pagination: true
          },        
        },
      },
    } 
   },
    methods: {
   
    onSlideStart(slider) {
        this.sliding = true
      },
    onSlideEnd(slider) {
        this.sliding = false
      },
    },
}
</script>

<style lang="scss" src="../scss/estilos.scss" scoped>

</style>
