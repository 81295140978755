import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      allowAnon: true
    }
  },
  {
    path: '/registro',
    name: 'Registro',
    component: () => import('../views/RegistroView.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
    path: '/registro-taxistas',
    name: 'Registro-Taxistas',
    component: () => import('../views/TaxistasViews/RegistroTaxistasView.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
    path: '/input-taxistas',
    name: 'Input-Taxistas',
    component: () => import('../views/TaxistasViews/InputTaxistasView.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
    path: '/login-taxistas',
    name: 'Login-Taxistas',
    component: () => import('../views/TaxistasViews/LoginTaxistasView.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
  path: '/panel-taxistas',
    name: 'Panel-Taxistas',
    component: () => import('../views/TaxistasViews/PanelTaxistasView.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
    path: '/login-comercios',
    name: 'Login-Comercios',
    component: () => import('../views/LoginComerciosView.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
    path: '/login-clientes',
    name: 'Login-Clientes',
    component: () => import('../views/LoginClientesView.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
    path: '/login-administradores',
    name: 'Login-Administradores',
    component: () => import('../views/LoginAdminView.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
    path: '/password-olvidado',
    name: 'Password-Olvidado',
    component: () => import('../views/PasswordOlvidadoView.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
    path: '/fotos',
    name: 'Fotos',
    component: () => import('../views/Fotos.vue'),
    meta: {
      allowAnon: true
    }
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('../views/ErrorView.vue'),
    meta: {
      allowAnon: true
    }
  }
]

const router = new VueRouter({
  routes
})

//APPGUARD QUE PROTEGE LAS RUTAS EN FUNCION DE SI SE PERMITE ACCESO 
//PUBLICO (ALLOWANON: TRUE) O PRIVADO (ALLOWANON: FALSE) 
router.beforeEach( (to, from, next) => {
  if(!to.meta.allowAnon && !isLoggedIn()) {
    next ({
      path: '/',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})
export default router
