<template>
  <div class="presentacionslider">
    <splide :options="options">
      <splide-slide>
        <div class="servidor-contain justify-content-center bg-white">
          <figure>
              <img class="btn-servidor" src="../assets/servidor.jpg" width="350" height="250"> 
                <h2 class="row justify-content-center servidor-title mt-3">Servidor en la nube</h2>
                <p class="row justify-content-center servidor_text mt-3">
                  Servidores cloud con una persistencia<br>
                  asegurada del 99,5%.
                </p>
          </figure>
        </div>
      </splide-slide>
      <splide-slide>
        <div class="voip-contain bg-white">
          <figure>
              <img class="btn-voip" src="../assets/voip.jpg" width="350" height="250"> 
                <h2 class="row justify-content-center vopip-title mt-3">VoIP en la nube</h2>
                <p class="row justify-content-center vopip_text mt-3">
                  Tu centralita telefónica en la nube. Sin<br>
                  averías y sin interrupciones. 
                </p>
          </figure>
        </div>
      </splide-slide>
      <splide-slide>
        <div class="operadoras2-contain bg-white">
          <figure>
              <img class="btn-operadoras2" src="../assets/operadoras2.jpg" width="350" height="250"> 
                <h2 class="row justify-content-center operadoras2-title mt-3">Operadoras</h2>
                <p class="row justify-content-center operadoras2_text mt-3">
                  El mejor sistema para la central, rápido y<br>
                  eficiente.<br> 
                </p>
          </figure>
        </div>
      </splide-slide>
      <splide-slide>
        <div class="pulsador-contain bg-white">
          <figure>
              <img class="btn-pulsador" src="../assets/pulsador.jpg" width="350" height="250"> 
                <h2 class="row justify-content-center pulsador-title mt-3">Pulsador Negocios</h2>
                <p class="row justify-content-center pulsador_text mt-3">
                  Pulsador para que comercios y hoteles<br>
                  soliciten taxis con una pulsación.<br>
                </p>
          </figure>
        </div>
      </splide-slide>
      <splide-slide>
        <div class="appclientes-contain bg-white">
          <figure>
              <img class="btn-appclientes" src="../assets/appclientes.jpg" width="350" height="250"> 
                <h2 class="row justify-content-center appclientes-title mt-3">App para Clientes</h2>
                <p class="row justify-content-center appclientes_text mt-3">
                  Los clientes podrán solicitar directamente<br>
                  el servicio desde su smartphone.<br> 
                </p>
          </figure>
        </div>
      </splide-slide>
      <splide-slide>
        <div class="apptaxi-contain bg-white">
          <figure>
              <img class="btn-apptaxi" src="../assets/apptaxi.jpg" width="350" height="250"> 
                <h2 class="row justify-content-center apptaxi-title mt-3">App Android para Taxi</h2>
                <p class="row justify-content-center apptaxi_text mt-3">
                  App Android para el taxi. Recepción de<br>
                  servicios, paradas, reservas... y mucho más.<br> 
                </p>
          </figure>
        </div>
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

export default {
  name: 'TaxiSlider',
  components: {
    Splide,
    SplideSlide,
  },
   data() {
    return {
     slider: 0,
      sliding: null,
      	options: {
			  	rewind : true,
				  arrows : true,
          perPage: 3,
          heightRatio: 1,
				  gap    : '0.5rem',
          type   : 'loop',
          autoplay: false,
          breakpoints: {
		        575: {
			        perPage: 1,
              arrows: false,
              pagination: false
            },
            767: {
			        perPage: 1,
              arrows: false,
              pagination: false
            },
            991: {
			        perPage: 2,
              arrows: false,
              pagination: true
            },
            1199: {
			        perPage: 2,
              arrows: true,
              pagination: true
            },
          },
        }
    } 
   },
    methods: {
      onSlideStart(slider) {
          this.sliding = true
        },
      onSlideEnd(slider) {
          this.sliding = false
        },
    },
}
</script>

<style lang="scss" src="../scss/estilos.scss" scoped>

</style>
